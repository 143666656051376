// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/media/tarek/bca7db99-8511-440d-8436-0d5f64d6d689/coding/tdp-photography/client/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("/media/tarek/bca7db99-8511-440d-8436-0d5f64d6d689/coding/tdp-photography/client/src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-contact-us-js": () => import("/media/tarek/bca7db99-8511-440d-8436-0d5f64d6d689/coding/tdp-photography/client/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("/media/tarek/bca7db99-8511-440d-8436-0d5f64d6d689/coding/tdp-photography/client/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("/media/tarek/bca7db99-8511-440d-8436-0d5f64d6d689/coding/tdp-photography/client/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/media/tarek/bca7db99-8511-440d-8436-0d5f64d6d689/coding/tdp-photography/client/.cache/data.json")

